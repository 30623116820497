import React, { useState } from "react";
import { graphql } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";
// import { ProductListing } from "./sub-components/collection"
import ShopSidebar from "../components/features/sidebar/shop-sidebar";
import ProductList from "../components/features/product/list/product-list";
import SEO from "../components/common/SEO";
import { getVisibleProducts } from "../services";

const Collection = (props) => {
  const { subCategory } = props.pageContext;
  const { data } = props;
  const allProducts = data.allProducts.nodes;
  const path = props.path.trim().split("/");

  const [filters, setfilters] = useState({
    category: [],
    condition: [],
    storeLocation: [],
    brand: [],
    color: [],
    rating: false,
    value: false,
    sortBy: "",
  });
  let grid = "4cols";

  const onSort = (filterVal) => {
    // console.log("Filter");
    setfilters({ ...filters, sortBy: filterVal });
  };
  const onPriceChange = (values) => {
    // console.log(values);
    setfilters({ ...filters, value: values });
  };
  const resetFilter = () => {
    setfilters({
      category: [],
      condition: [],
      storeLocation: [],
      brand: [],
      color: [],
      rating: false,
      value: false,
      sortBy: "",
    });
  };
  const onFilter = ({ item, key }) => {
    if (key === "storeLocation") {
      let index = filters.storeLocation.findIndex((el) => el === item._id);

      let locations = filters.storeLocation;
      if (-1 === index) {
        locations.push(item._id);
      } else {
        locations.splice(index, 1);
      }
      setfilters({ ...filters, storeLocation: locations });
      // console.log(filters);
    } else if (key === "condition") {
      // console.log(item);
      let index = filters.condition.findIndex((el) => el === item);
      let condition = filters.condition;
      if (-1 === index) {
        condition.push(item);
      } else {
        condition.splice(index, 1);
      }
      setfilters({ ...filters, condition: condition });
    } else if (key === "categories") {
      // console.log(item);
      let index = filters.category.findIndex((el) => el === item._id);

      let categories = filters.category;
      if (-1 === index) {
        categories.push(item._id);
      } else {
        categories.splice(index, 1);
      }
      // console.log(categories);
      setfilters({ ...filters, category: categories });
      // console.log(filters);
    }
  };
  let products = getVisibleProducts(allProducts, filters);

  const location = props.location?.pathname.split("/")[2];

  return (
    <MainLayout>
      <SEO
        title={subCategory?.seoMetaData.title || subCategory?.name}
        category={subCategory}
      />

      <Breadcrumb
        title={`${subCategory?.name}`}
        parent={["collections", `collections/${location}`]}
      />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 skeleton-body skel-shop-products">
              <ProductList
               categoryName={subCategory?.name}
               column={grid}
               products={products}
               onSort={onSort}
               filters={filters}
              />
            </div>

            <div className="col-lg-3 order-lg-first skeleton-body skel-shop-sidebar">
              <div className="skel-widget"></div>

              <div className="skel-widget"></div>

              <div className="skel-widget"></div>

              <div className="skel-widget"></div>

              <ShopSidebar
                 categories={[]}
                 adClass="sidebar sidebar-shop"
                 storeLocations={data.metadata?.storeLocation}
                 resetFilter={resetFilter}
                 onPriceChange={onPriceChange}
                 onFilter={onFilter}
                 showCategories={path.includes("collections")}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <ProductListing stores={customApi.metadata.storeLocation} products={products} /> */}
    </MainLayout>
  );
};

export default Collection;

export const query = graphql`
  query getAllSubcollection($ido: String) {
    metadata {
      id
      storeLocation {
        _id
        name
      }
    }
    categories: allCategories {
      nodes {
        _id
        name
        itemCount
        subCategories {
          name
        }
      }
    }
    allProducts(filter: { subCategory: { eq: $ido } }) {
      nodes {
        _id
        title
        description
        quantity
        price
        oldPrice
        rating
        isDefaultShippingEnabled
        isDeliverable

        isPickup
        isShippable
        condition
        seoMetaData {
          slug
        }
        storeLocation {
          _id
          name
          state
          zipCode
          address
        }
        image: thumbnail {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 75, height: 75, quality: 100, layout: FIXED)
          }
        }
        category {
          _id
          name
        }
        subCategory
        createdAt
      }
    }
  }
`;
